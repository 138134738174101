import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import BlogPostCardV2I from "./BlogPostCardV2I.type";
import * as styles from "./BlogPostCardV2.module.scss";
import {
  getReadingTimeFromRaw,
  getTruncatedContent,
  truncateString,
} from "../../../utils/helpers";

const BlogPostCardV2 = ({ blog: { node }, wide = false }: BlogPostCardV2I) => {
  const readTime = getReadingTimeFromRaw([
    node.sectionOneContent?.raw ? node.sectionOneContent.raw : "",
    node.sectionTwoContent?.raw ? node.sectionTwoContent.raw : "",
    node.sectionThreeContent?.raw ? node.sectionThreeContent.raw : "",
  ]);
  return (
    <div className={styles.cardContainer}>
      <a href={`/blog/${node.slug}`} className={styles.cardLink}>
        <div className={styles.cardImgContainer}>
          {node!.heroImage && (
            <GatsbyImage
              image={node!.heroImage?.gatsbyImageData}
              alt=""
            ></GatsbyImage>
          )}
        </div>
        <div className={styles.cardBlogTag}>{node!.blogTag?.tagName}</div>
        <div className={styles.cardBlogTitle}>
          {wide ? node!.blogTitle : truncateString(node!.blogTitle)}
        </div>
        <div
          className={wide ? styles.cardBlogExcerptWide : styles.cardBlogExcerpt}
        >
          {wide
            ? getTruncatedContent(node!.sectionOneContent.raw, 300)
            : node!.excerpt}
        </div>
        <div className={styles.cardReadTime}>{readTime}</div>
      </a>
    </div>
  );
};

export default BlogPostCardV2;
