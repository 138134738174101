import BlogCuratedCardI from "./BlogCuratedCardI.type";
import * as styles from "./BlogCuratedCard.module.scss";
import { setStyle } from "../../../utils/helpers";

const BlogCuratedCard = ({ newTrending }: BlogCuratedCardI) => {
  setStyle(".gatsby-image-wrapper {height: 100% !important;}");
  return (
    <div>
      <div className={styles.curatedContainer}>
        <h2>New & Trending</h2>
        {newTrending.map(blog => (
          <div className={styles.trendingBubble}>
            <a href={blog.slug}>{blog.blogTitle}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogCuratedCard;
