import BlogHomeHeader from "../BlogHomeHeader/BlogHomeHeader";
import BlogHomeLayoutI from "../BlogHomeLayout/BlogHomeLayoutI.type";
import * as styles from "./BlogHomeLayout.module.scss";
import { cn } from "../../../utils";
import FeatureCard from "../../Blocks/FeatureCard/FeatureCard";
import BlogCuratedCard from "../BlogCuratedCard/BlogCuratedCard";
import BlogListLayout from "../../BlogList/BlogListLayout/BlogListLayout";

const BlogHomeLayout = ({
  allBlogTags,
  homePageBlogs,
  blogs,
  currentPage,
  numPages,
  setShowModal,
}: BlogHomeLayoutI) => {
  const featuredBlog = homePageBlogs.featuredBlog;
  return (
    <div className={styles.blogHomeWrapper}>
      <div className={styles.blogHomeLayoutContainer}>
        <BlogHomeHeader allBlogTags={allBlogTags}></BlogHomeHeader>
        {currentPage === 1 && (
          <div className={styles.gridWrapper}>
            <div className={cn([styles.gridBox, styles.featureBox])}>
              <FeatureCard
                tag={featuredBlog.blogTag?.tagName}
                title={featuredBlog.blogTitle}
                subTitle={featuredBlog.excerpt}
                image={{
                  gatsbyImageData: featuredBlog.heroImage.gatsbyImageData,
                  title: featuredBlog.heroImage.title,
                }}
                link={featuredBlog.slug}
                sectionOneContent={featuredBlog.sectionOneContent}
                sectionTwoContent={featuredBlog.sectionTwoContent}
                sectionThreeContent={featuredBlog.sectionThreeContent}
              ></FeatureCard>
            </div>
            <div className={cn([styles.gridBox, styles.curatedCard])}>
              <BlogCuratedCard
                newTrending={homePageBlogs.newTrending}
              ></BlogCuratedCard>
            </div>
          </div>
        )}
        <BlogListLayout
          blogs={blogs}
          setShowModal={setShowModal}
          currentPage={currentPage}
          numPages={numPages}
        ></BlogListLayout>
      </div>
    </div>
  );
};

export default BlogHomeLayout;
