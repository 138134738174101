// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var blogHomeLayoutContainer = "BlogHomeLayout-module--blogHomeLayoutContainer--296Fv";
export var blogHomeWrapper = "BlogHomeLayout-module--blogHomeWrapper--2weep";
export var gridWrapper = "BlogHomeLayout-module--gridWrapper--2rThB";
export var gridBox = "BlogHomeLayout-module--gridBox--1jc17";
export var featureBox = "BlogHomeLayout-module--featureBox--17KY1";
export var curatedCard = "BlogHomeLayout-module--curatedCard--zrbro";
export var wideCard = "BlogHomeLayout-module--wideCard--1fCL0";
export var ctaCard = "BlogHomeLayout-module--ctaCard--UQ8sv";