// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var cardContainer = "BlogPostCardV2-module--cardContainer--2-IdK";
export var cardImgContainer = "BlogPostCardV2-module--cardImgContainer--1Wdru";
export var cardBlogTag = "BlogPostCardV2-module--cardBlogTag--lgN5w";
export var cardBlogTitle = "BlogPostCardV2-module--cardBlogTitle--26u-x";
export var cardBlogExcerpt = "BlogPostCardV2-module--cardBlogExcerpt--2Tg5R";
export var cardBlogExcerptWide = "BlogPostCardV2-module--cardBlogExcerptWide--1-Dpc";
export var cardReadTime = "BlogPostCardV2-module--cardReadTime--1XQuc";
export var cardLink = "BlogPostCardV2-module--cardLink--3dMXv";