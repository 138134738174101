// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var navigationContainer = "BlogListLayout-module--navigationContainer--w2Bfx";
export var blogListContainer = "BlogListLayout-module--blogListContainer--qR7cF";
export var gridWrapper = "BlogListLayout-module--gridWrapper--3Vath";
export var activeLink = "BlogListLayout-module--activeLink--37Y6j";
export var gridBox = "BlogListLayout-module--gridBox--15Z34";
export var wideCard = "BlogListLayout-module--wideCard--196B1";
export var ctaCard = "BlogListLayout-module--ctaCard--1zPHR";
export var bottomCtaContainer = "BlogListLayout-module--bottomCtaContainer--3G8SO";