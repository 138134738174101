import BlogHomeHeaderI from "./BlogHomeHeaderI.type";

const BlogHomeHeader = ({ allBlogTags }: BlogHomeHeaderI) => {
  return (
    <div>
      <h1 style={{ display: "none" }}>Via Blog</h1>
    </div>
  );
};

export default BlogHomeHeader;
