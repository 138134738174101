import BlogListLayoutI from "./BlogListLayoutI.type";
import * as styles from "./BlogListLayout.module.scss";
import BlogPostCardV2 from "../../BlogPost/BlogPostCardV2/BlogPostCardV2";
import { cn } from "../../../utils";
import SectionCTA from "../../Sections/SectionCTA/SectionCTA";
import { Link } from "gatsby";
import TitleI from "../../../types/Title.type";

const BlogListLayout = ({
  blogs,
  setShowModal,
  currentPage,
  numPages,
}: BlogListLayoutI) => {
  const Title = {
    type: "section",
    title: "Need help building your global team?",
    title_color: "white",
    second_line: false,
    second_title_color: "",
  };
  const BottomTitle: TitleI = {
    title: "We make it simple to hire across countries, so you ",
    title_color: "white",
    second_title: "can focus on running your global business",
    second_title_color: "gold",
    second_line: false,
  };
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage === 2
      ? "/blog/"
      : "/blog/" + (currentPage - 1).toString() + "/";
  const nextPage = "/blog/" + (currentPage + 1).toString() + "/";
  return (
    <div className={styles.blogListContainer}>
      <div className={styles.gridWrapper}>
        {blogs.length >= 3 ? (
          <>
            {blogs.slice(0, 3).map(blog => {
              return (
                <div className={styles.gridBox}>
                  <BlogPostCardV2 blog={blog}></BlogPostCardV2>
                </div>
              );
            })}
            {blogs[3] ? (
              <>
                <div className={cn([styles.gridBox, styles.ctaCard])}>
                  <SectionCTA
                    title={Title}
                    button={{
                      button_text: "Talk to us",
                    }}
                    smallTitle={""}
                    borderRadius={"13px"}
                    light={true}
                    btnSize={"full"}
                    setModal={setShowModal}
                  ></SectionCTA>
                </div>
                <div className={cn([styles.gridBox, styles.wideCard])}>
                  <BlogPostCardV2 wide={true} blog={blogs[3]}></BlogPostCardV2>
                </div>
              </>
            ) : (
              ""
            )}
            {blogs.length > 5
              ? blogs.slice(4).map(blog => {
                  return (
                    <div className={styles.gridBox}>
                      <BlogPostCardV2 blog={blog}></BlogPostCardV2>
                    </div>
                  );
                })
              : ""}
          </>
        ) : (
          blogs.map(blog => {
            return (
              <div className={styles.gridBox}>
                <BlogPostCardV2 blog={blog}></BlogPostCardV2>
              </div>
            );
          })
        )}
      </div>
      <div className={styles.navigationContainer}>
        {!isFirst && (
          <Link to={prevPage} rel="prev">
            {"<"}
          </Link>
        )}
        {Array.from({ length: numPages + 1 })
          .slice(2)
          .map((_, i) => {
            return i + 2 == currentPage ? (
              <span className={styles.activeLink}>{i + 2}</span>
            ) : (
              <Link to={`/blog/${i + 2}/`}>{i + 2}</Link>
            );
          })}
        {!isLast && (
          <Link to={nextPage} rel="next">
            {">"}
          </Link>
        )}
      </div>
      <div className={styles.bottomCtaContainer}>
        <SectionCTA
          title={BottomTitle}
          button={{
            button_text: "Talk to us",
          }}
          borderRadius={"13px"}
          smallTitle={""}
          light={false}
          btnSize={"full"}
          setModal={setShowModal}
        ></SectionCTA>
      </div>
    </div>
  );
};

export default BlogListLayout;
