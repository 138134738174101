import { graphql } from "gatsby";
import { useEffect, useState } from "react";
import BlogHomeLayout from "../components/BlogHome/BlogHomeLayout/BlogHomeLayout";
import CookiesBanner from "../components/CookiesBanner/CookiesBanner";
import Layout from "../components/Layout/Layout";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import { useCookie } from "../utils/getCookie";

const blogHome = ({ data, pageContext }: any) => {
  const { homePageBlogs, allBlogTags, seo, currentPage, numPages } =
    pageContext.data;
  const [showCookiesConsent, setShowCookiesConsent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let blogs = data?.allContentfulBlogPost.edges;
  useEffect(() => {
    const trackCookie = useCookie("gatsby-gdpr-tracking");
    const tagCookie = useCookie("via-gdpr-google-tagmanager");
    if (!trackCookie || !tagCookie) {
      setShowCookiesConsent(true);
    }
  }, []);
  const {
    footer_menu,
    header_menu,
    footer,
    logo,
    cookieBarDescription,
    cookiePolicyLink,
    cookieBarTitle,
  } = pageContext.globalSettings;
  return (
    <div>
      <Layout
        footer_menu={footer_menu}
        header_menu={header_menu}
        footer={footer}
        logo={logo}
        background={true}
        page_background={true}
        dataSEO={seo}
        countryPage={false}
        blog={true}
        url={pageContext.url}
        setModal={setShowModal}
        white={false}
      >
        {showCookiesConsent && (
          <CookiesBanner
            dismiss={() => setShowCookiesConsent(false)}
            cookieBarDescription={cookieBarDescription}
            cookiePolicyLink={cookiePolicyLink}
            cookieBarTitle={cookieBarTitle}
          />
        )}
        <BlogHomeLayout
          homePageBlogs={homePageBlogs}
          blogs={blogs}
          allBlogTags={allBlogTags}
          setShowModal={setShowModal}
          currentPage={currentPage}
          numPages={numPages}
        ></BlogHomeLayout>
        {showModal && (
          <RegisterForm modal={showModal} setModal={setShowModal} />
        )}
      </Layout>
    </div>
  );
};

export const query = graphql`
  query myPostsForPage($limit: Int, $skip: Int, $filter: [String]) {
    allContentfulBlogPost(
      sort: { fields: publishedDate, order: DESC }
      filter: { id: { nin: $filter } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          blogTitle
          author {
            fullName
            bio {
              bio
            }
          }
          excerpt
          blogTag {
            tagName
            slug
          }
          heroImage {
            gatsbyImageData(
              placeholder: BLURRED
              width: 2048
              height: 708
              formats: WEBP
            )
            title
          }
          sectionOneContent {
            raw
          }
          sectionTwoContent {
            raw
          }
          sectionThreeContent {
            raw
          }
          publishedDate(formatString: "MMM Do, YYYY")
        }
      }
    }
  }
`;

export default blogHome;
